import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubFooter from "../components/sub-page/sub-footer"
import NetworkBackground from "../images/services/networkoptimization-background.jpg"

const SecurityPage = () => (
  <Layout>
    <Seo title="Network Management" />
    <div
      className="rounded-xl shadow-xl py-24 mx-auto px-6 sm:py-12 md:py-36 md:px-10"
      style={{
        backgroundImage: `url(${NetworkBackground})`,
        backgroundSize: `cover`,
      }}
    >
      <div className="sm:text-left lg:text-left">
        <h1 className="text-4xl tracking-tight font-heading font-extrabold text-white sm:text-5xl md:text-6xl lg:text-8xl">
          Cyber <span className="text-sun">Security</span>
        </h1>
        <p className="mt-3 text-base font-body text-white sm:mt-5 sm:text-lg sm:max-w-2xl sm:mx-0 md:mt-5 md:text-xl lg:mx-0">
          We fortify and optimize your network so you can work with piece of mind knowing you're safe.
        </p>
      </div>
    </div>
    <div className="mt-16 mb-24 text-white flex justify-center flex-wrap gap-6">
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/security#cyber" className="h-full">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Cyber Security
            </h2>
          </div>
        </AnchorLink>
      </div>
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/security#firewall" className="h-full">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Firewall Management
            </h2>
          </div>
        </AnchorLink>
      </div>
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/security#email" className="h-full">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Email Protection
            </h2>
          </div>
        </AnchorLink>
      </div>
      <div className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat rounded-lg shadow-xl">
        <AnchorLink to="/security#network">
          <div
            className="transition flex justify-center items-center ease-in-out duration-500 bg-insight bg-opacity-75 p-8 rounded-lg group-hover:bg-opacity-100"
            style={{ width: "250px", minHeight: "200px" }}
          >
            <h2 className="text-2xl text-center font-heading font-bold pb-2">
              Network Security
            </h2>
          </div>
        </AnchorLink>
      </div>
    </div>
    <div className="my-24 mx-auto px-6 md:px-10">
      <div
        id="cyber"
        className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Cyber Security</h2>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              Cybersecurity. Computer Security. Email Security. These are all
              ways in which a company protects their systems, networks, and
              programs from digital attacks; attacks that aim to manipulate
              sensitive information for financial gain which can affect your
              business operations.
            </p>
            <p className="text-xl pt-4 max-w-6xl">
              Implementing effective cybersecurity measures can be challenging
              because attackers are becoming more innovative in finding new ways
              to compromise these systems. i360 combats these threats the same
              way a large company does by evaluating all areas of your business
              technology to provide the best multi-layered defense against these
              modern-day threats.
            </p>
          </div>
        </div>
      </div>
      <div
        id="firewall"
        className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Firewall Management</h2>
            </div>
          </div>
          <div>
            <h3 className="pb-6 text-2xl">What is it and why do I need it?</h3>
            <p className="text-xl max-w-6xl">
              With managed firewall security services, i360 takes care of
              configuring your firewall, managing rulesets, ensuring compliance,
              and analyzing traffic logs. We monitor your network, bandwidth
              consumption and application usage across all sites, searching for
              signals that indicate imminent threats. We can institute firewall
              alerts that give you real time notification of adverse events and
              log the events for your network administrators. These security
              alerts can be configured to trigger actions that immediately go
              into effect, all to protect your network.
            </p>
            <p className="text-xl pt-4 max-w-6xl">
              We can also institute safeguards that will block, shape or
              whitelist activity – depending on your business’s needs – to
              optimize performance and user experience. That means you can
              fine-tune your firewall’s protection to your exact needs, as
              needed.
            </p>
            <h3 className="py-6 text-2xl">Why you need Firewall Management?</h3>
            <p className="text-xl max-w-6xl">
              With a managed firewall service, your business is automatically
              protected from unauthorized traffic, including breaches that allow
              the entrance of spyware, malware, viruses, and other malicious
              code that damages your systems; exploits vulnerabilities and –
              ultimately – brings downs your network and your business.
            </p>
            <p className="text-xl pt-4 max-w-6xl">
              Firewall security is about more than just installing a firewall
              and walking away, thinking you are completely protected. It
              requires maintenance and regular updates to stay ahead of these
              threats to your organization. i360 provides comprehensive, managed
              firewall security solutions that give you piece of mind.
            </p>
          </div>
        </div>
      </div>
      <div
        id="email"
        className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Email Protection</h2>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              Protecting your email communication from today’s threats, whether
              spam or viruses, is critical in today’s business environment. Our
              managed email security ensures that your company’s inboxes are
              protected from evolving threats so your employees can continue to
              work without having to waste time sifting through junk mail. Our
              filters also protect mailboxes from email-borne viruses that can
              cripple computers and even entire networks.
            </p>
          </div>
        </div>
      </div>
      <div
        id="network"
        className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10"
      >
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-8">Network Security</h2>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              Best-in-class gateway protection from external threats caused by
              spyware, viruses, and network intrusions which:
              <ul className="text-xl list-disc mt-4 ml-8">
                <li>
                  Prevents internet-based threats from entering your network
                </li>
                <li>Helps prevent workstation performance loss</li>
                <li>Protects from loss, exploitation, or corruption of data</li>
                <li>
                  Ensures network users are participating in permissible
                  browsing and internet activities
                </li>
                <li>
                  Minimizes financial damage to your company through compromised
                  computing systems, lost productivity and theft of passwords
                  and other confidential information
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
    <SubFooter />
  </Layout>
)

export default SecurityPage
